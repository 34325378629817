import { useEffect } from "react";
import { pageTitles } from "../../config/page.config";
import { updatePageTitle } from "../../Helper/Utility";

const ServiceUnavailable: React.FC = () => {

    useEffect(() => {
        updatePageTitle(pageTitles.serviceUnavailable);
    }, []);

    return (
        <div className="nhsuk-width-container">
            <main
                className="nhsuk-main-wrapper nhsuk-main-wrapper--l"
                id="maincontent"
                role="main"
            >
                <div className="nhsuk-grid-row">
                    <div className="nhsuk-grid-column-two-thirds">
                        <div className="nhsuk-u-padding-bottom-4"></div>
                        <h1>
                            Sorry, the service is temporarily unavailable
                        </h1>
                        <p className="nhsuk-body">
                            DARS Online is currently unavailable whilst we make changes to the user interface. DARS online will be available again at 9am Friday 20th December 2024.
                        </p>                        
                        <p className="nhsuk-body">
                            If you need help, contact NHS England on 0300 303 5678, or email <a href="mailto:enquiries@nhsdigital.nhs.uk">enquiries@nhsdigital.nhs.uk
                            </a>
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ServiceUnavailable;